import axios from 'axios'
import * as Sentry from '@sentry/browser'

import { useUserStore } from '@/stores/UserStore'

const apiClient = (isBatchProcessor = false, useMultipartForm = false) => {
  let baseURL = import.meta.env.VITE_API_HOST
  const userStore = useUserStore()

  const headers = {}

  if (userStore.token) {
    headers['Authorization'] = `Bearer ${userStore.token}`
  }

  if (useMultipartForm) {
    headers['Content-Type'] = `multipart/form-data`
  }

  if (isBatchProcessor) {
    baseURL = import.meta.env.VITE_BATCH_PROCESSOR_API_HOST
  }

  const axiosClient = axios.create({
    baseURL,
    headers
  })

  axiosClient.interceptors.response.use(request => request, (error) => {
    console.log('Error details:', error);
    
    if (error.response) {
      // The request was made, and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('Data:', error.response.data)
      console.log('Status:', error.response.status)
      console.log('Headers:', error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in Node.js
      console.log('Request:', error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Message:', error.message)
    }

    if (![401, 406].includes(error.response.status) && error.response.data.message !== 'An active subscription already exists' && !error.response.data.message.includes('no account found for')) {
      Sentry.captureException(error)
    }
   
    return error
  })

  return axiosClient
}



const apiClientWithoutAuth = () => {
  return axios.create({
    baseURL: import.meta.env.VITE_API_HOST,
  })
}

const apiBuildTxt = () => {
  return axios.create({
    baseURL: import.meta.env.VITE_MAIN_SITE,
  })
}

const apiStrapi = async (apiUrl, queryParams, extraQuery) => {
  const strapiApi = import.meta.env.VITE_STRAPI_API_HOST
  const strapiToken = import.meta.env.VITE_STRAPI_TOKEN

  const queryString = new URLSearchParams(queryParams).toString()
  let apiUrlWithParams = `${strapiApi}${apiUrl}?${queryString}`

  if (extraQuery) {
      apiUrlWithParams = `${apiUrlWithParams}&${extraQuery}`
  }

  const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${strapiToken}`,
      },
  }

  try {
      const response = await fetch(apiUrlWithParams, requestOptions)
      const responseData = await response.json()
      return responseData
  } catch (error) {
      console.error('Error StrapiApi', error)
  }
}

export {
  apiClient,
  apiClientWithoutAuth,
  apiStrapi,
  apiBuildTxt
}
