import { defineStore } from 'pinia'
import { apiStrapi } from "@/helpers/api.js"

export const useStrapiStore = defineStore('Strapi', {
    state: () => {
        return {
            banner: {},
            changeLog: [],
            coachingCards: [],
            resources: [],
            notificationStatus: {},
            videoTour: {},
            gettingStartedSteps: [],
        }
    },
    actions: {
        async fetchAllStrapiData(){
            this.fetchGettingStartedSteps()
            this.fetchVideoTour()
            this.fetchBanner()
            this.fetchChangeLogData()
            this.fetchResources()
            this.fetchSystemStatusNotificationBar()
        },

        async fetchBanner() {
            try {
                const { data } = await apiStrapi(
                    '/cdp-banners', 
                    {
                        populate: '*',
                        publicationState: 'live'
                    }, 
                    'pagination[limit]=1'
                )
                if(data.length === 0){
                    return
                }
                const { id } = data[0]
                const { description, headline, ctaLink, ctaLabel, image } = data[0].attributes

                this.banner = {
                    description, headline, id, ctaLink, ctaLabel,
                    imageUrl: `https://content.carddealerpro.com${image.data.attributes.url}`
                }
            } catch (error) {
                console.error('Error fetching data from the Banner API:', error)
            }
        },

        async fetchChangeLogData() {
            try {
                const { data } = await apiStrapi(
                    '/cdp-changelog-posts', 
                    {
                        sort:'date:DESC'
                    }, 
                    'populate[0]=section,improvement&populate[1]=section.video,section.images&populate[2]=section.video.file,section.video.poster'
                )

                this.changeLog = data
            } catch (error) {
                console.error('Error fetching data from the ChangeLog API:', error)
            }
        },

        async fetchCoachingCards() {
            try {
                const { data } = await apiStrapi(
                    '/cdp-coaching-cards', 
                    {
                        populate: '*',
                    }, 
                )
                
                if(data.length === 0){
                    this.coachingCards = []
                    return
                }
                this.coachingCards = data.filter(card => !card.attributes.showOnlyInDev)
                .map(card => {
                    const { title, headline, ctaLabel, ctaLink, description, coverImage, video, path, youtubeLink, priority } = card.attributes
                    const id = card.id
                    return {
                      id, title, headline, ctaLabel, ctaLink, description, path, youtubeLink, priority,
                      coverImage: coverImage.data ? `https://content.carddealerpro.com${coverImage.data.attributes.url}` : {},
                      video: video.data ? `https://content.carddealerpro.com${video.data.attributes.url}` : {},
                    }
                  })
                  

            } catch (error) {
                console.error('Error fetching data from the Coaching Cards API:', error)
            }
        },

        async fetchGettingStartedSteps() {
            try {
                const { data } = await apiStrapi(
                    '/Cdp-getting-started-steps', 
                    'Cdp-getting-started-steps?populate[0]=video&populate[1]=video.file, video.poster'
                )
                if(data.length === 0){
                    this.gettingStartedSteps = []
                    return
                }
                this.gettingStartedSteps = data.map(step => {
                    const { name, title, done, description, video: videoComponent, route, routeText, canSkip } = step.attributes
                    const id = step.id
                    const video = {}
                    if(videoComponent){
                        video.src = `https://content.carddealerpro.com${videoComponent.file.data.attributes.url}`
                        video.poster = `https://content.carddealerpro.com${videoComponent.poster.data.attributes.url}`
                    }
                    return {
                      id, name, title, done, description, route, routeText, canSkip,video
                    }
                  })
            } catch (error) {
                console.error('Error fetching data from the Getting Started Steps API:', error)
            }
        },

        async fetchResources() {
            try {
                const { data } = await apiStrapi(
                    '/cdp-resources', 
                    {
                        populate: '*',
                    }, 
                    'pagination[limit]=3&filters[featured]=true'
                )

                this.resources = data
            } catch (error) {
                console.error('Error fetching data from the Resources API:', error)
            }
        },

        async fetchVideoTour() {
            try {
                const { data } = await apiStrapi(
                    '/video-tour', 
                    {
                        populate: '*',
                    }
                )
                const { coverImage, headline, video } = data.attributes

                this.videoTour = {
                    headline,
                    videoUrl: `https://content.carddealerpro.com${video.data.attributes.url}`,
                    imageUrl: `https://content.carddealerpro.com${coverImage.data.attributes.url}`,
                }
            } catch (error) {
                console.error('Error fetching data from the Video Tour API:', error)
            }
        },    

        async fetchSystemStatusNotificationBar() {
            try {
                const { data } = await apiStrapi(
                    '/system-notification', 
                    {
                        populate: '*',
                    }
                )
                if(!data){
                    this.notificationStatus = {}
                    return
                }
                const { headline, message, display } = data.attributes

                if(!display){
                    this.notificationStatus = {}
                    return
                }
                
                this.notificationStatus = {
                    headline, message
                }
            } catch (error) {
                console.error('Error fetching data from the System Notification API:', error)
            }
        },    
    },
})