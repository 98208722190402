import { defineStore, acceptHMRUpdate } from 'pinia'

import { apiClient, apiClientWithoutAuth, apiStrapi } from "@/helpers/api.js"
import { useBatchStore } from '@/stores/BatchStore'
import * as Sentry from '@sentry/browser'

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user: null,
      loading: true,
      loggedIn: false,
      // email: '',
      token: '',
      overrideAuth: false,
      loginError: null,
      
      plan: 'Rookie',

      subscriptions: [],
      inventorySettings: {},
      titleTemplates: [],
      descriptionTemplates: [],
      selectedTemplate: {
        name: '',
        content: '',
        default: false,
        id: null
      },
      customFields: [],
      uploadingAvatar: false,
      uploadingBanner: false,
      loadingSubscription: false,
      loadingTemplates: false,
      loadingInvoices: false,
      verificationFail: false,
      verificationLinkExpired: false,
      verificationSuccess: false,
      updatePasswordFail: false,
      updatePasswordSuccess: false,
      emailAlreadyExists: false,
      userCreated: false,
      notFoundEmail: false,

      scanners: [],
      invoices: [],

      lookupEmailInfo: null,

      promoCodeInfo: {},
      promoCodeError: null,

      paymentMethod: {},
      paymentError: {},
      createPaymentError: {},
      addonsPaymentError: {},
      loadingActivationSubscription: false,
      activationSubscriptionSuccess: false,
      activationSubscriptionError: false,

      updateSubscriptionSuccess: false,
      updateSubscriptionError: false,

      paymentMethods: [],
      createPaymentMethodLoading: false,

      cancelSubscriptionSuccess: false,

      buyMoreScansSuccess: false,
      buyMoreScansError: false,

      changePasswordError: null,
      lookupWinbackInfo: null,

      linkToMarketplaceLoader: false,
      redirectMobileScanUrl: '',
      loginOtpPasswordLoader: false,
      loginOtpPasswordErrorMessage: '',
      otpLoginDone: false
    }
  },
  getters: {
    invoicesList() {
      return this.invoices ? this.invoices.filter(item => item.total > 0) : []
    },
    flags() {
      return JSON.parse(this.user.flags)
    }
  },
  actions: {
    updateUserField(field, value) {
      this.user = { ...this.user, [field]: value }

      const authData = localStorage.getItem("cdp_auth")
      const sessionData = JSON.parse(authData)

      sessionData.user = this.user
      localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
    },

    async checkSession(overrideAuthToken = null) {
      if (overrideAuthToken) {
        try {
          this.token = overrideAuthToken
          const response = await apiClient().get(`/users/me`)
          this.user = response.data
          this.loggedIn = true  
          this.overrideAuth = true
          sessionStorage.setItem('cdp_auth_override_token', overrideAuthToken)
        } catch (e) {
          console.log('checkSession error using override auth', e)
          this.user = null
          this.loggedIn = false
        }
      }

      if (!this.loggedIn || !this.user) {
        let authData = localStorage.getItem("cdp_auth")
        if (authData !== null && authData !== "") {
          try {
            const data = JSON.parse(authData)
            this.token = data.token
            this.user = data.user
            this.loggedIn = true
          } catch (e) {
            console.log('checkSession error', e)
            this.loggedIn = false
          }
        }

        if (this.loggedIn) {
          apiClient().get(`/users/me`).then(response => {
            this.user = response.data
            const authData = {
              token: this.token,
              user: response.data,
            }
            localStorage.setItem("cdp_auth", JSON.stringify(authData))
          })
        }
      }
    },

    async logIn(email, password) {
      this.loginError = null
      let success = false

      try {
        const response = await apiClientWithoutAuth().post('/users/login/', {email, password})
        
        const data = response.data

        if (data?.ok) {
          const sessionData = {
            token: data.token,
            user: data.user,
          }
          localStorage.setItem("cdp_auth", JSON.stringify(sessionData))

          this.token = data.token
          this.user = data.user
          // this.email = data.user.email
          this.loggedIn = true
          success = true
        } else {
          this.loginError = 'Login failed'
        }
      } catch (e) {
        console.error('logIn error', e)
        this.loginError = e.response.data.message
      }
      return success
    },

    async logOut() {
      const batchStore = useBatchStore()

      localStorage.removeItem('cdp_auth')
      localStorage.removeItem("collx_user")
      sessionStorage.removeItem('cdp_auth_override_token')
      this.token = ""
      this.loggedIn = false
      batchStore.config = {}
    },

    async createUser(body) {
      try {
        const { response, data }  = await apiClient().post('/users', body)

        if (response && response.data.message.includes('already exists')) {
          this.emailAlreadyExists = true
        } else {
          this.emailAlreadyExists = false
          this.userCreated = true
        } 
      } catch (error) {
        console.error('createUser error', error)   
      }
    },

    async verificationResetPassword(token) {
      this.verificationLinkExpired = false

      try {
        const { data, response } = await apiClient().get(`/users/verify-reset-password/${token}`)

        if (data?.ok) {
          this.token = token

          this.verificationFail = false
          this.verificationSuccess = true
        } else {
          this.verificationFail = true

          this.verificationLinkExpired = true
        } 
      } catch (error) {
        this.verificationFail = true
        console.error('verificationResetPassword error', error)

        this.verificationLinkExpired = true
      }
    },

    async updatePassword(body) {
      try {
        const { data } = await apiClientWithoutAuth().post(`/users/update-password`, { ...body, token: this.token })

        if (data?.ok) {
          this.token = data.token

          const { data: userData } = await await apiClient().get(`/users/me`)

          const sessionData = {
            token: data.token,
            user: userData,
          }
          localStorage.setItem("cdp_auth", JSON.stringify(sessionData))

          this.user = userData
          this.loggedIn = true

          this.updatePasswordFail = false
          this.updatePasswordSuccess = true
        } else {
          this.updatePasswordFail = true
          throw(new Error('updatePassword'))
        }
      } catch (error) {
        this.updatePasswordFail = true
        console.error('updatePassword error', error)
      }
    },

    async verificationEmail(token) {
      this.verificationLinkExpired = false

      try {
        const { data } = await apiClient().get(`/users/verify-email/${token}`)

        if (data?.ok) {
          localStorage.removeItem("cdp_auth")
          this.token = ""
          this.loggedIn = false

          const sessionData = {
            token: data.token,
            user: data.user,
          }
          localStorage.setItem("cdp_auth", JSON.stringify(sessionData))

          this.token = data.token
          this.user = data.user
          
          this.loggedIn = true

          this.verificationFail = false
          this.verificationSuccess = true

         
        } else {
          this.verificationFail = true

          this.verificationLinkExpired = true
        } 
      } catch (error) {
        this.verificationFail = true
        console.error('verificationEmail error', error)

        this.verificationLinkExpired = true
      }
    },

    async reverificationEmail(email) {
      try {
        const { data } = await apiClient().post(`/users/email-verification`, { email })

        if (data?.ok) {
          this.verificationFail = false
          this.verificationSuccess = true
        } else {
          this.verificationFail = true
        } 
      } catch (error) {
        this.verificationFail = true
        console.error('reverificationEmail error', error)
      }
    },

    async lookupEmail(email) {
      try {
        const { data } = await apiClient().post(`/users/lookup`, {
          email
        })
       
        this.lookupEmailInfo = { ...data }

        return data
      } catch (error) {
        console.error('lookupEmail error', error)
      }
    },

    async resetPassword(body) {
      try {
        this.notFoundEmail = false

        await apiClient().post(`/users/reset-password`, body)
      } catch (error) {
        if (error.response.data.message === 'The email does not exist') {
          this.notFoundEmail = true
        }
        console.error('resetPassword error', error)
      }
    },

    async fetchScanners() {
      try {
        const { data } = await apiStrapi(
          '/scanner-models', 
          {
              populate: '*',
          }
        )

        const mappedScanners = data.map(scanner => {
          return scanner.attributes.section
        })

        this.scanners = mappedScanners
      } catch (error) {
        console.error('resetPassword error', error)
      }
    },

    async fetchUser() {
      try {
        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData)

        const { data } = await apiClient().get('/users/me')

        this.user = { ...data }

        if (sessionData) {
          sessionData.user = { ...data }
          localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
        }
      } catch (error) {
        console.error('fetchUser error', error)
      }
    },

    async updateUser(body) {
      try {
        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData) || {}

        const { data } = await apiClient().patch('/users/me', body)
        this.user = { ...data, banner_placement: sessionData.user?.banner_placement }

        sessionData.user = { ...data, banner_placement: sessionData.user?.banner_placement }
        localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
      } catch (error) {
        console.error('updateUser error', error)
      }
    },

    async deleteUser(body) {
      try {
        const { data } = await apiClient().delete('/users/me', body)
        this.logOut()
      } catch (error) {
        console.error('deleteUser error', error)
      }
    },

    async updateAvatar(avatar) {
      try {
        this.uploadingAvatar = true
        const { data } = await apiClient().post('/users/me/avatar-image', avatar)

        this.user = { ...this.user, ...data  }

        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData)

        sessionData.user = { ...this.user }
        localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
        this.uploadingAvatar = false
      } catch (error) {
        console.error('updateAvatar error', error)
        this.uploadingAvatar = false
      }
    },

    async removeAvatar() {
      try {
        const { data } = await apiClient().delete('/users/me/avatar-image')

        this.user = { ...this.user, avatar_image: '' }

        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData)

        sessionData.user = { ...this.user }
        localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
      } catch (error) {
        console.error('removeAvatar error', error)
      }
    },

    async fetchInventorySettings() {
      try {
        this.loadingSubscription = true
        const { data } = await apiClient().get('/users/me/subscription')
        
        this.inventorySettings = data
        this.loadingSubscription = false
      } catch (error) {
        this.loadingSubscription = false
        console.error('fetchInventorySettings error', error)
      }
    },

    async updateInventorySettings(body) {
      try {
        const { data } = await apiClient().patch('/users/me/subscription', body)

        this.inventorySettings = data
      } catch (error) {
        console.error('updateInventorySettings error', error)
      }
    },

    async addBanner(banner, placement) {
      try {
        this.uploadingBanner = true
        const { data } = await apiClient().post('/users/me/banner-image', banner)

        this.user = { ...this.user, ...data, banner_placement: placement }

        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData)

        sessionData.user = { ...this.user }
        localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
        this.uploadingBanner = false
      } catch (error) {
        console.error('addBanner error', error)
        this.uploadingBanner = false
      }
    },

    async removeBanner() {
      try {
        const { data } = await apiClient().delete('/users/me/banner-image')

        this.user = { ...this.user, banner_image: ''  }

        const authData = localStorage.getItem("cdp_auth")
        const sessionData = JSON.parse(authData)

        sessionData.user = { ...this.user }
        localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
      } catch (error) {
        console.error('removeBanner error', error)
      }
    },

    async fetchCustomFields() {
      try {
        const { data } = await apiClient().get('/custom-fields')

        this.customFields = data.map(item => ({...item, editing: false}))
      } catch (error) {
        console.error('fetchCustomFields error', error)
      }
    },

    async createCustomField(body) {
      try {
        const { data } = await apiClient().post('/custom-fields', body)

        this.fetchCustomFields()
      } catch (error) {
        console.error('createCustomField error', error)
      }
    },

    async updateCustomField(field) {
      try {
        const { data } = await apiClient().patch(`/custom-fields/${field.id}`, field)

        this.fetchCustomFields()
      } catch (error) {
        console.error('updateCustomField error', error)
      }
    },

    async deleteCustomField(fieldId) {
      try {
        const { data } = await apiClient().delete(`/custom-fields/${fieldId}`)

        this.fetchCustomFields()
      } catch (error) {
        console.error('deleteCustomField error', error)
      }
    },

    async changePassword(body) {
      try {
        const { data, response } = await apiClient().post('/users/me/change-password', body)

        if (response) {
          this.changePasswordError = response.data
        }
      } catch (error) {
        console.error('changePassword error', error)
      }
    },

    async fetchTemplates() {
      try {
        this.loadingTemplates = true
        const { data } = await apiClient().get('/templates')

        const titleTemplates = []
        const descriptionTemplates = []

        for(const template of data) {
          template.type === 'title' && titleTemplates.push(template)
          template.type === 'description' && descriptionTemplates.push(template)
        }

        this.titleTemplates = titleTemplates
        this.descriptionTemplates = descriptionTemplates
        this.loadingTemplates = false
      } catch (error) {
        this.loadingTemplates = false
        console.error('fetchTemplates error', error)
      }
    },

    async createTemplate(body, type) {
      const batchStore = useBatchStore()

      try {
        const { data } = await apiClient().post('/templates', body)

        this[type] = [ ...this[type], data]

        batchStore.fetchConfigs()

        return data
      } catch (error) {
        console.error('createTemplate error', error)
      }
    },

    async updateTemplate(id, body, key) {
      const batchStore = useBatchStore()

      try {
        const { data } = await apiClient().put(`/templates/${id}`, body)

        this[key] = this[key].reduce((acc, item) => {
          if (item.id !== id) {
            acc.push(item)
          } else {
            acc.push(body)
          }

          return acc
        }, [])

        batchStore.fetchConfigs()

        return data
      } catch (error) {
        console.error('updateTemplate error', error)
      }
    },

    async deleteTemplate(id, type) {
      const batchStore = useBatchStore()

      try {
        const { data } = await apiClient().delete(`/templates/${id}`)

        this[type] = this[type].reduce((acc, item) => {
          if (item.id !== id) {
            acc.push(item)
          }

          return acc
        }, [])

        batchStore.fetchConfigs()
        
      } catch (error) {
        console.error('deleteTemplate error', error)
      }
    },

    async fetchInvoices(id, type) {
      try {
        this.loadingInvoices = true
        const { data, response } = await apiClient().get(`/users/me/invoices`)
        
        if (!data || !data?.ok) {
          throw new Error(response?.data?.message)
        } else {
          this.invoices = data.data
        }

        this.loadingInvoices = false
      } catch (error) {
        this.loadingInvoices = false
        console.error('fetchInvoices error', error)
        Sentry.captureException(error)
      }
    },

    async validatePromoCode(code) {
      try {
        const { data } = await apiClient().post(`/promo_codes/validate`, {
          code
        })

        if (data?.ok) {
          this.promoCodeInfo = { ...data.promo_code, message: data.message }

          return this.promoCodeInfo
        } else {
          this.promoCodeError = data.message
        }
      } catch (error) {
        console.error('validatePromoCode error', error)
      }
    },

    async activeSubscription(body) {
      try {
        this.loadingActivationSubscription = true
        const { data, response } = await apiClient().post(`/users/${this.user.id}/subscription/activate`, body)

        if (response || (data && !data?.ok)) {
          this.paymentError = data || response.data
          throw Error(data)
        } else {
          this.paymentError = data
 
          this.activationSubscriptionSuccess = true

          await this.fetchUser()

          this.loadingActivationSubscription = false

          return this.activationSubscriptionSuccess
        }
      } catch (error) {
        console.error('activeSubscription error', error)
        this.loadingActivationSubscription = false
        this.activationSubscriptionError = true
        Sentry.captureException(error)
      }
    },

    async cancelSubscription(body) {
      try {
        const { data } = await apiClient().post(`/users/me/subscription/cancel`, body)
        this.cancelSubscriptionSuccess = true
      } catch (error) {
        console.error('cancelSubscription error', error)
        Sentry.captureException(error)
      }
    },

    async updateSubscription(body) {
      this.updateSubscriptionSuccess = false
      this.updateSubscriptionError = false
      try {
        const { data, response } = await apiClient().post(`/users/me/subscription/update-plan`, body)

        if (!data || !data.ok || response) {
          this.updateSubscriptionError = true
        } else {
          this.updateSubscriptionSuccess = true
        }

      } catch (error) {
        console.error('updateSubscription error', error)
        Sentry.captureException(error)
        this.updateSubscriptionError = true
      }
    },

    async fetchPaymentMethod() {
      try {
        const { data } = await apiClient().get(`/users/me/payment-methods`)

        if (!data?.ok) {
          throw Error(data)
        } else {
          this.paymentMethods = data.data
        }
      } catch (error) {
        console.error('fetchPaymentMethod error', error)
        Sentry.captureException(error)
      }
    }, 

    async createPaymentMethod(body, errorField = 'paymentError') {
      try {
        this.createPaymentMethodLoading = true
        const { response, data } = await apiClient().post(`/users/me/payment-methods`, body)

        if (response) {
          this[errorField] = response.data
        } else {
          this[errorField] = data
        }

        this.createPaymentMethodLoading = false
      } catch (error) {
        this.createPaymentMethodLoading = false
        console.error('createPaymentMethod error', error)
        Sentry.captureException(error)
      }
    },

    async setDefaultPaymentMethod(stripe_payment_method_id) {
      try {
        const { data } = await apiClient().post(`/users/me/payment-methods/${stripe_payment_method_id}/set-default`)
      } catch (error) {
        console.error('setDefaultPaymentMethod error', error)
        Sentry.captureException(error)
      }
    },

    async deletePaymentMethod(stripe_payment_method_id) {
      try {
        await apiClient().delete(`/users/me/payment-methods/${stripe_payment_method_id}`)

        this.paymentMethods = this.paymentMethods.filter(item => item.id !== stripe_payment_method_id)
      } catch (error) {
        console.error('deletePaymentMethod error', error)
        Sentry.captureException(error)
      }
    },

    async buyMoreScans(body) {
      try {
        const { data } = await apiClient().post(`/add_ons`, body)

        if (data?.ok) {
          this.buyMoreScansSuccess = data
        } else {
          this.buyMoreScansError = data
        }
      } catch (error) {
        console.error('buyMoreScans error', error)
        this.buyMoreScansError = { ok: false }
        Sentry.captureException(error)
      }
    },

    async captureWinbackCampaign(body) {
      try {
        const { data, response } = await apiClient().post(`/users/capture-campaign`, body)

        if (!data && !response?.data?.ok) {
          this.lookupWinbackInfo = null
        }

        if (data?.ok) {
          this.lookupWinbackInfo = data.user
        }

      } catch (error) {
        console.error('captureWinbackCampaign error', error)
        Sentry.captureException(error)
      }
    },

    async linkToMarketplace(code, marketplaceName) {
      try {
        this.linkToMarketplaceLoader = true

        const { data } = await apiClient().post(`/users/me/link-ebay`, { code })

        if (data?.ok) {
          await this.fetchUser()
          this.linkToMarketplaceLoader = false
        }
      } catch (error) {
        console.error('linkToMarketplace error', error)
        Sentry.captureException(error)
        this.linkToMarketplaceLoader = false
      }
    },

    async unlinkToEbay() {
      try {
        const { data } = await apiClient().post(`/users/me/unlink-ebay`)
        if (data?.ok) {
          await this.fetchUser()
        }
      } catch (error) {
        console.error('unlinkToEbay error', error)
        Sentry.captureException(error)
      }
    },

    async loginOtpPassword(password) {
      try {
        this.loginOtpPasswordLoader = true
        const { data } = await apiClient().post(`users/otp-login`, {
          password
        })

        if (data?.ok) {
          const sessionData = {
            token: data.token,
            user: data.user,
          }
          localStorage.setItem("cdp_auth", JSON.stringify(sessionData))

          this.token = data.token
          this.user = data.user
          this.loggedIn = true
          this.loginOtpPasswordLoader = false
          this.redirectMobileScanUrl = data.redirect_to

          return  data.redirect_to
        }

        if (data?.message) {
          this.loginOtpPasswordErrorMessage = data.message
        }

        this.loginOtpPasswordLoader = false
      } catch (error) {
        this.loginOtpPasswordLoader = false

        this.loginOtpPasswordErrorMessage = error.response?.data?.message
        console.log('loginOtpPassword error', error)
      }
    },

    async sendEvent(event, payload = {}) {
      try {
        const { data } = await apiClient().post(`users/me/events`, {
          event,
          payload
        })
      } catch (error) {
        console.log('sendEvent error', error)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
