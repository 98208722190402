import { defineStore, acceptHMRUpdate } from 'pinia'
import { apiClient } from "@/helpers/api.js"
import { getDefaultTemplateForStore } from '@/helpers/connectionsAndExport.js'
import {
  RECOMMENDED_CONNECTION_CHANNELS,
  ALL_CONNECTION_CHANNELS,
  DEFAULT_TEMPLATE,
  EBAY_CHANNEL,
  SHOPIFY_CHANNEL,
  SIMPLE_AUCTION_CHANNEL,
  COLLX_CHANNEL,
} from '@/constants'
import { useUserStore } from '@/stores/UserStore'
import { useBatchStore } from '@/stores/BatchStore'
import image5 from '@/assets/images/place-cards/5.png'

export const useConnectionStore = defineStore('ConnectionStore', {
  state: () => {
    return {
      channels: [...ALL_CONNECTION_CHANNELS],
      recommendedChannels: [ ...RECOMMENDED_CONNECTION_CHANNELS ],

      listingTemplates: [],
      marketplaceListingTemplates: [],
      whatnotListingTemplates: [],
      collxListingTemplates: [],
      shopifyListingTemplates: [],
      loadingListingTemplates: false,
      marketplaceLoadingListingTemplates: false,
      selectedTemplateId: null,
      selectedTemplate: { ...DEFAULT_TEMPLATE },

      openedListingDialog: false,
      openedOverviewDialog: false,
      addingTemplate: false,
      templateCreation: false,

      isItExport: false,
      isItEbayConnection: false,

      steps: [ ],

      loadingExports: false,
      exports: [],
      export: {},

      downloadData: null,

      shouldBeUpdated: false,

      exportedChannelInfo: {},
      showExportToast: false,

      collxUser: null,
      collxAccountInfo: null,
      collxAuthLoader: false,
      collxAuthError: null,
      collxAccountLoader: false,

      collxSending: false,
      collxSent: false,
      collxSendError: false,

      ebaySending: false,
      ebaySent: false,
      ebaySendError: false,

      whatnotSending: false,
      whatnotSent: false,
      whatnotSendError: false,

      pagination: null,

      collxErrorModal: false,

      policies: [],
      ebayStoreCategories: [
        { /* StoreCategoryType */
            "categoryId": "1",
            "categoryName": "Test category",
            "childrenCategories": [
              {
                "categoryId": "21",
                "categoryName": "Test category 21",
                "childrenCategories": [
                  {
                    "categoryId": "3",
                    "categoryName": "Test category 3",
                    "childrenCategories": [],
                    "level": 1,
                    "order": 1
                  }
                ],
                "level": 1,
                "order": 1
              }
            ],
            "level": 1,
            "order": 1
        },
        { /* StoreCategoryType */
            "categoryId": "2",
            "categoryName": "Test category 2",
            "childrenCategories": [
              {
                "categoryId": "22",
                "categoryName": "Test category 22",
                "childrenCategories": [
                  {
                    "categoryId": "33",
                    "categoryName": "Test category 33",
                    "childrenCategories": [],
                    "level": 1,
                    "order": 1
                  }
                ],
                "level": 1,
                "order": 1
              }
            ],
            "level": 1,
            "order": 1
        }
      ],
      ebayStoreCategoriesForRender: [
        { /* StoreCategoryType */
            "categoryId": "1",
            "categoryName": "Test category",
            "childrenCategories": [
              {
                "categoryId": "21",
                "categoryName": "Test category 21",
                "childrenCategories": [
                  {
                    "categoryId": "3",
                    "categoryName": "Test category 3",
                    "childrenCategories": [],
                    "level": 1,
                    "order": 1
                  }
                ],
                "level": 1,
                "order": 1
              }
            ],
            "level": 1,
            "order": 1
        },
        { /* StoreCategoryType */
            "categoryId": "2",
            "categoryName": "Test category 2",
            "childrenCategories": [
              {
                "categoryId": "22",
                "categoryName": "Test category 22",
                "childrenCategories": [
                  {
                    "categoryId": "33",
                    "categoryName": "Test category 33",
                    "childrenCategories": [],
                    "level": 1,
                    "order": 1
                  }
                ],
                "level": 1,
                "order": 1
              }
            ],
            "level": 1,
            "order": 1
        }
      ],
      storeCategoriesLevel: 1,
      selectedStoreCategory: null,
      selectedStoreParentCategory: null,
      loadingPolicies: false,
      isEbayDirect: false
    }
  },
  getters: {
    addedChannels() {
      const userStore = useUserStore()
      const isEbay = userStore.user?.ebay_enabled
      const isShopify = userStore.user?.shopify_enabled
      const isAuction = userStore.user?.simple_auction_enabled
      const isCollx = userStore.user?.collx_enabled
      const channels = []

      if (isEbay) {
        channels.push({ ...EBAY_CHANNEL })
      }

      if (isShopify) {
        channels.push({ ...SHOPIFY_CHANNEL })
      }

      if (isAuction) {
        channels.push({ ...SIMPLE_AUCTION_CHANNEL })
      }

      if (isCollx) {
        channels.push({ ...COLLX_CHANNEL })
      }

      return channels
    },
    remainChannels: (state) => state.channels.filter(
      channel => !state.addedChannels.some(item => { return channel.name === item.name})
    ),
  },
  actions: {
    removeChannel(channel) {
      let idx = this.addedChannels.findIndex(el => el.name === channel.name)
      this.addedChannels.splice(idx, 1)
    },

    setReturnsFieldsToNull() {
      this.selectedTemplate.returns_within = null
      this.selectedTemplate.refund_given_as = null
      this.selectedTemplate.return_shipping_paid_by = null
    },

    async updateTemplate(id, body, type = 'listingTemplates') {
      try {
        const { data } = await apiClient().patch(`/export-profiles/${id}`, body)

        this[type] = this[type].reduce((acc, item) => {
          if (item.id !== id) {
            acc.push(item)
          } else {
            acc.push({ ...data, profile: { ...data.profile, auto_accept_offer: Boolean(data.profile?.auto_accept_offer), auto_decline_offer: Boolean(data.profile?.auto_decline_offer) } })
          }

          return acc
        }, [])
      } catch (error) {
        console.error('updateTemplate error', error)
      }
    },

    async fetchTemplates(type = 'listingTemplates', listingType = 'ebay') {
      try {
        this.loadingListingTemplates = true

        const { data } = await apiClient().get(`/export-profiles?type=${listingType}`)
        this[type] = data.reduce((acc, item) => {
          const item_ = { ...item, auto_accept_offer: Boolean(item.auto_accept_offer), auto_decline_offer: Boolean(item.auto_decline_offer) }

          acc.push(item_)

          return acc
        }, [])

        if (this[type].length === 1) {
          await this.updateTemplate(this[type][0].id, { default: true })

          this.loadingListingTemplates = false
          this.selectedTemplate = { ...getDefaultTemplateForStore(this[type]) }

          return this[type]
        }

        this.selectedTemplate = { ...getDefaultTemplateForStore(this[type]) }

        this.loadingListingTemplates = false
        return this[type]
      } catch (error) {
        console.error('fetchConnection error', error)
        this.loadingListingTemplates = false
      }
    },

    async fetchTemplateById() {
      try {
        const { data } = await apiClient().get(`/export-profiles/${this.selectedTemplateId}`)

      } catch (error) {
        console.error('fetchTemplateById error', error)
      }
    },

    async createTemplate(body, type = 'listingTemplates') {
      try {
        const { data } = await apiClient().post(`/export-profiles`, body)
        this[type] = [ ...this[type], data ]
        this.selectedTemplate = { ...data }
      } catch (error) {
        console.error('createTemplate error', error)
      }
    },

    async deleteTemplate(id, type = 'listingTemplates') {
      try {
        const { data } = await apiClient().delete(`/export-profiles/${id}`)
        this[type] = this[type].reduce((acc, item) => {
          if (item.id !== id) {
            acc.push(item)
          }

          return acc
        }, [])

        if (!this[type].length) {
          this.selectedTemplate = { ...DEFAULT_TEMPLATE }
        }
      } catch (error) {
        console.error('deleteTemplate error', error)
      }
    },

    async createExport(batchId, channels, cards, activeChannelList) {
      const batchStore = useBatchStore()

      const exportChannels = channels ? channels.reduce((acc, item) => {
        acc[item.field] = true

        return acc
      }, {}) : {
        ebay_enabled: true,
       }

      const cardIds = cards.map(card => card.id)

      if (!this.selectedTemplate) {
        this.selectedTemplate = this[activeChannelList][0]
      }

      const profile = getDefaultTemplateForStore(this[activeChannelList], this.selectedTemplate)
      if (profile?.ebay_category) {
        this.selectedTemplate.ebay_category = profile.ebay_category
      }

      try {
        const { data } = await apiClient().post(`/exports`, {
          status: 'pending',
          batch_id: Number(batchId),
          cards: cardIds,
          ebay_enabled: exportChannels['ebay_enabled'] || false,
          shopify_enabled: exportChannels['shopify_enabled'] || false,
          simple_auction_enabled: exportChannels['simple_auction_enabled'] || false,
          collx_enabled: false,
          profile
        })

        this.export = data
      } catch (error) {
        console.error('createExport error', error)
      }
    },

    async updateExport(exportId, channels) {
      try {
        const { data } = await apiClient().patch(`/exports/${exportId}`, {
          profile: this.selectedTemplate,
        })

      } catch (error) {
        console.error('updateExport error', error)
      }
    },

    async fetchExports(query) {
      try {
        this.loadingExports = true
        const { data } = await apiClient().get('/exports', {
          params: query
        })

        this.exports = data.items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        this.loadingExports = false
      } catch (error) {
        console.error('fetchExports error', error)
        this.loadingExports = false
      }
    },

    async fetchExportById(exportId) {
      try {
        this.loadingExports = true

        const { data } = await apiClient().get(`/exports/${exportId}`)

        this.export = data
        this.selectedTemplate = data.profile
        this.loadingExports = false
      } catch (error) {
        this.loadingExports = false
        console.error('fetchExports error', error)
      }
    },

    async downloadCsv(id, channel) {
      try {
        let url = `/exports/${id}/csvs/${channel.toLowerCase()}`

        // if (channel === 'zip') {
        //   url = `/exports/${id}/zip`
        // }

        const { data } = await apiClient().get(`${url}?token=${this.export.token}`)
        this.downloadData = data
      } catch (error) {
        console.error('downloadCsv error', error)
      }
    },

    async logInToCollX(email, password) {
      try {
        this.collxAuthLoader = true

        const collxResponse = await apiClient().post('/users/me/link-collx', {email, password})

        if (collxResponse.data && collxResponse.data?.user.collx_user_id) {
          const userStore = useUserStore()
          const authData = localStorage.getItem("cdp_auth")
          const sessionData = JSON.parse(authData)

          userStore.user = { ...userStore.user, ...collxResponse.data.user }

          if (sessionData) {
            sessionData.user = { ...sessionData.user, ...collxResponse.data.user }
            localStorage.setItem("cdp_auth", JSON.stringify(sessionData))
          }

          localStorage.setItem("collx_user",  JSON.stringify(collxResponse.data.user))
          this.collxUser = collxResponse.data?.user
          this.collxAuthLoader = false
        } else if (!collxResponse.response.data.ok) {
          this.collxAuthLoader = false

          if (collxResponse.response.data.message.includes('Invalid password')) {
            this.collxAuthError = 'Email & Password does not match with our record.'
          } else if (collxResponse.response.data.message.includes('no account found for')) {
            this.collxAuthError = 'We couldn’t find an account under this email.'
          } else {
            this.collxAuthError = collxResponse.response.data.message
          }
        }
      } catch (error) {
        this.collxAuthLoader = false
        console.error('logInToCollX error', error)
      }
    },

    async fetchCollxAccount() {
      try {
        this.collxAccountLoader = true
        const response = await apiClient().get('/users/me/collx')

        if (response.data?.ok) {
          this.collxAccountInfo = response.data?.data?.viewer

          this.collxAccountLoader = false
        }
      } catch (error) {
        console.error('fetchCollxAccount error', error)
        this.collxAccountLoader = false
      }
    },

    async sendToCollX(type, batch_id, cards) {
      try {
        const collxSelectedTemplate = this.collxListingTemplates[0]
        this.collxSendError = false
        this.collxSending = true
        const { data } = await apiClient().post(`/jobs/${type}`, {
          batch_id,
          type,
          data: {
            listing_price: collxSelectedTemplate.listing_price,
            price_adjustment_type: collxSelectedTemplate.price_adjustment_type,
            start_price: collxSelectedTemplate.start_price,
            calculated_method: collxSelectedTemplate.calculated_method,
            fixed_amount_to_price: collxSelectedTemplate.fixed_amount_to_price,
            percent_to_price: collxSelectedTemplate.percent_to_price,
          },
          cards
        })

        if (data?.ok) {
          this.collxSent = data.job.status === 'pending'
          this.showExportToast = true
          this.collxSending = false
          this.exportedChannelInfo = {...COLLX_CHANNEL, countOfCards: cards.length}
        } else {
          this.collxSendError = true
          this.collxSending = false
        }
      } catch (error) {
        console.error('sendToCollX error', error)
        this.collxSendError = true
        this.collxSending = false
      }
    },

    async fetchSentCollxJobs(options = {}) {
      try {
        const { page = 1, type = 'send-to-collx' } = options

        this.loadingExports = true
        const { data } = await apiClient().get(`/jobs/${type}?page=${page}`)

        this.pagination = {
          perPage: 25,
          currentPage: data.current_page,
          totalItems: data.total_items,
          nextPage: data.next_page ? data.current_page + 1 : null,
          prevPage: data.current_page === 1 ? null : data.current_page - 1,
          totalPages: data.total_pages
        }

        this.exports = data.items || []
        this.loadingExports = false
      } catch (error) {
        console.error('fetchSentCollxJobs error', error)
        this.loadingExports = false
      }
    },

    async fetchSentCollxJobsById(jobId) {
      try {
        this.loadingExports = true

        const { data } = await apiClient().get(`/jobs/id/${jobId}`)

        this.export = data
        this.selectedTemplate = data
        this.loadingExports = false
      } catch (error) {
        this.loadingExports = false
        console.error('fetchSentCollxJobsById error', error)
      }
    },

    async fetchEbayPolicies(jobId) {
      const userStore = useUserStore()

      try {
        this.loadingPolicies = true

        const { data } = await apiClient().get(`/ebay/locations-and-policies`)

        if (data?.ok) {
          const data_ = { ...data }
          const { locations } = data
          const { city, state, postal_code, country } = userStore.user

          if (!locations.length && city && state && postal_code && country) {
            data_.locations.push({
              name: 'CDP Default Location',
              city,
              state,
              country,
              postal_code
            })
          }

          this.policies = data_
        }

        this.loadingPolicies = false
      } catch (error) {
        this.loadingPolicies = false
        console.error('fetchEbayPolicies error', error)
      }
    },

    async fetchEbayStoreCategories() {
      try {
        // const { data } = await apiClient().get(`/ebay/categories`)

        // if (data?.ok) {
        //   this.ebayStoreCategories = data
        // }

      } catch (error) {
        console.error('fetchEbayStoreCategories error', error)
      }
    },

    async unlinkCollx(jobId) {
      const userStore = useUserStore()

      try {
        await apiClient().post(`/users/me/unlink-collx`)
        await userStore.fetchUser()
      } catch (error) {
        console.error('unlinkCollx error', error)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConnectionStore, import.meta.hot))
}
